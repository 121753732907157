import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import StoreContext from '../../context/StoreContext';
import Spinner from '../Spinner';
import FileUpload from '../FileUpload';
import formatCurrency from '../../utils/formatCurrency';
import useClub from '../../hooks/useClub';
import uploadEve from './uploadEve';
import { productRequiresConsent, productRequiresStickerId } from '../../config';

const uploadStates = {
  PENDING: 'pending',
  UPLOADING: 'uploading',
  DONE: 'done',
};

const ProductForm = ({ product }) => {
  const { variants } = product;
  const [initialVariant] = variants;
  const { availableForSale } = initialVariant
  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);
  const [stickerNumber, setStickerNumber] = useState('1');
  const [eveFile, setEveFile] = useState(undefined);
  const [uploadState, setUploadState] = useState(uploadStates.PENDING);
  const [added, setAdded] = useState(false);

  // Does this product require the customer to upload the consent PDF?
  const requiresConsent = productRequiresConsent(product.shopifyId);

  const requiresStickerId = productRequiresStickerId(product.shopifyId);

  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext);

  const { club } = useClub();

  if (!availableForSale) {
    return null;
  }

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const handleStickerNumber = event => {
    const {
      target: { value },
    } = event;
    setStickerNumber(value);

    if (added) {
      setEveFile(undefined);
      setUploadState(uploadStates.PENDING);
      setAdded(false);
    }
  };

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value);
  };

  const handleVariantChange = ({ target }) => {
    setVariant(variants.find(({ id }) => id === target.value));
  };

  const handleConsent = async () => {
    if (requiresConsent) {
      setUploadState(uploadStates.UPLOADING);
      const fileName = await uploadEve(eveFile);
      setUploadState(uploadStates.DONE);

      return fileName;
    }

    return 'not-required';
  };

  const handleAddToCart = async () => {
    const consentFileName = await handleConsent();

    const metaFields = [{
      key: 'projectId',
      value: club.project_id.toString(),
    }];

    if (requiresConsent) {
      metaFields.push({
        key: 'eveFile',
        value: consentFileName,
      });
    }

    if (requiresStickerId) {
      metaFields.push({
        key: 'stickerNumber',
        value: stickerNumber,
      });
    }

    await addVariantToCart(productVariant.shopifyId, quantity, metaFields);

    setAdded(true);
  };

  const price = formatCurrency(variant.price);

  return (
    <>
      <h2>{price}</h2>
      <div className="grid">
        <div className="row">
          <div className="col">
            {variants.length > 1 &&
              <div className="field">
                <label htmlFor="variant">Variante</label>
                <select
                  id="variant"
                  data-testid="qa-variant"
                  value={variant.id}
                  onChange={handleVariantChange}
                >
                  {variants.map(v => (
                    <option key={v.id} value={v.id}>
                      {v.title}
                    </option>
                  ))}
                </select>
              </div>
            }
            {requiresStickerId && (
              <div className="field">
                <label htmlFor="stickerNumber">Stickernummer</label>
                <select
                  id="stickerNumber"
                  data-testid="qa-sticker-number-select"
                  value={stickerNumber}
                  onChange={handleStickerNumber}
                >
                  {club &&
                    Array(club.available_numbers)
                      .fill()
                      .map((_, number) => (
                        <option key={number} value={number + 1}>
                          {number + 1}
                        </option>
                      ))}
                </select>
              </div>
            )}
            <div className="field">
              <label htmlFor="quantity">Anzahl</label>
              <input
                type="number"
                id="quantity"
                data-testid="qa-quantity"
                name="quantity"
                min="1"
                step="1"
                onChange={handleQuantityChange}
                value={quantity}
              />
            </div>
            <button
              type="submit"
              data-testid="qa-add-to-cart-btn"
              disabled={
                adding || (requiresConsent && !eveFile) || uploadState === uploadStates.UPLOADING
              }
              onClick={handleAddToCart}
            >
              {adding ? <Spinner /> : 'Hinzufügen'}
            </button>
            {added && <div>Hinzugefügt!</div>}
          </div>
          {requiresConsent && (
            <div className="col">
              <div className="field">
                <FileUpload
                  label="Einverständniserklärung"
                  loading={uploadState === uploadStates.UPLOADING}
                  done={uploadState === uploadStates.DONE}
                  onChange={file => setEveFile(file)}
                  allowedExtensions={['jpg', 'jpeg', 'png', 'pdf']}
                  maxSize={15}
                />
              </div>
              <div style={{ fontSize: '0.8rem', opacity: 0.5 }}>
                Für dieses Produkt benötigst du die Einwilligung der
                abgebildeten Personen. Bitte fülle die Erklärung aus und lade
                sie hier hoch.
              </div>
              <div style={{ marginTop: '1.5rem' }}>
                <a
                  className="button alt"
                  target="_BLANK"
                  href="/sst_shop_consent.pdf"
                >
                  Erklärung herunterladen
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
};

export default ProductForm;
