import React, { useState, useRef } from 'react';

import Spinner from '../Spinner';

import './style.css';

const FileUpload = ({
  label,
  onChange,
  loading,
  done,
  maxSize,
  allowedExtensions,
}) => {
  const [file, setFile] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const [dragOver, setDragOver] = useState(false);
  const fileInput = useRef(null);

  const validate = file => {
    const errors = [];

    const nameParts = file.name.split('.');
    const ext = nameParts[nameParts.length - 1];

    if (
      allowedExtensions.map(s => s.toLowerCase()).indexOf(ext.toLowerCase()) < 0
    ) {
      errors.push(`Dateiformat muss ${allowedExtensions.join(', ')} sein`);
    }

    if (maxSize * 1000 * 1000 <= file.size) {
      errors.push(`Datei muss kleiner als ${maxSize} MB sein`);
    }

    return {
      errors,
    };
  };

  const handleChange = e => {
    const {
      target: {
        files: [file],
      },
    } = e;

    if (!file) {
      return;
    }

    const { errors: validationErrors } = validate(file);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setFile(file);
    setErrors([]);
    onChange && onChange(file);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleDragOver = e => {
    e.preventDefault();

    setDragOver(true);
  };

  const handleDrop = e => {
    e.preventDefault();

    const {
      dataTransfer: { items },
    } = e;

    handleChange({
      target: { files: Array.from(items).map(f => f.getAsFile()) },
    });
    setDragOver(false);
  };

  return (
    <>
      <label htmlFor="file_input">{label}</label>
      <input
        ref={fileInput}
        id="file_input"
        type="file"
        onChange={handleChange}
        data-testid="qa-file-upload-input"
      />
      <div
        className="file-upload"
        data-testid="qa-file-upload-dropzone"
        onClick={handleClick}
        onDrop={handleDrop}
        style={{ border: dragOver ? '2px solid black' : null }}
        onDragOver={handleDragOver}
        onDragLeave={() => setDragOver(false)}
      >
        {done ? (
          '✅ hochgeladen!'
        ) : file ? (
          loading ? (
            <Spinner />
          ) : (
            <div>
              <b>{file.name}</b>
              <br />
              {Math.round(file.size / 1000) / 1000} mb
            </div>
          )
        ) : (
          <>
            <span role="img">📤</span>
            <div>
              Datei auswählen
              <br /> oder ablegen
            </div>
          </>
        )}
      </div>
      {errors.length > 0 && (
        <div>
          <ul>
            {errors.map(err => (
              <li key={err}>❌ {err}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default FileUpload;
