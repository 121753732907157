import AWS from 'aws-sdk';

AWS.config.update({ region: 'eu-central-1' });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'eu-central-1:ec40019a-f68f-477d-9873-12ce82ffc2f4',
});

const lambda = new AWS.Lambda({
  region: 'eu-central-1',
  apiVersion: '2019-01-01',
});

const requestParams = {
  FunctionName: 'getShopEVEUploadUrl',
  InvocationType: 'RequestResponse',
};

const getUploadUrl = fileType =>
  new Promise((resolve, reject) => {
    lambda.invoke(
      {
        ...requestParams,
        Payload: JSON.stringify({
          fileType,
        }),
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (data && data.Payload) {
            const results = JSON.parse(data.Payload.toString());
            resolve(results);
          }
        }
      }
    );
  });

export default file => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      getUploadUrl(file.type).then(({ uploadURL, fileName }) => {
        fetch(uploadURL, {
          method: 'PUT',
          body: new Blob([reader.result], { type: file.type }),
        }).then(() => resolve(fileName));
      });
    });
    reader.readAsArrayBuffer(file);
  });
};
