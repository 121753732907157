import React, { useCallback } from 'react';
import { graphql } from 'gatsby';

import ProductForm from '~/components/ProductForm';
import StickerForm from '../../components/StickerForm';
import {
  Container,
  TwoColumnGrid,
  GridLeft,
  GridRight,
} from '~/utils/styles';
import { isStickerProduct, isGlitterStickerProduct } from '../../config';
import { GatsbyImage } from 'gatsby-plugin-image';

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct;

  const isSticker = isStickerProduct(product.shopifyId);

  const renderFormComponent = useCallback(() => {
    if (isSticker) {
      const isGlitter = isGlitterStickerProduct(product.shopifyId);
      return <StickerForm product={product} isGlitter={isGlitter} />;
    }

    return <ProductForm product={product} />;
  }, [product, isSticker]);

  return (
    <>
      <Container>
        <TwoColumnGrid>
          <GridLeft>
            {product.images.map(image => (
              <GatsbyImage image={image.gatsbyImageData} alt={product.title} key={image.id} />
            ))}
          </GridLeft>
          <GridRight>
            <h1 style={{ maxWidth: '80%' }}>{product.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
            {renderFormComponent()}
          </GridRight>
        </TwoColumnGrid>
      </Container>
    </>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        availableForSale
        id
        title
        price
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      images {
        originalSrc
        id
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;

export default ProductPage;
