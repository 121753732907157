import React, { useState } from 'react';

const StickerNode = ({ number, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <span
      key={number}
      className="badge"
      data-testid={`qa-sticker-node-${number}`}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered ? 'x' : number}
    </span>
  );
};

export default StickerNode;
