import React, { useRef, useMemo, useState, useContext } from 'react';
import uuidv4 from 'uuid/v4';
import { Buffer } from 'buffer';

import StoreContext from '../../context/StoreContext';
import useClub from '../../hooks/useClub';
import useCart from '../../hooks/useCart';
import InputSelect from '../InputSelect';
import StickerNode from './StickerNode';
import { isStickerProduct, landwirtschaftShopId } from '../../config';
import formatCurrency from '../../utils/formatCurrency';

import './style.css';

const StickerForm = ({ product, isGlitter = false }) => {
  const {
    variants: [variant],
  } = product;

  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const { club } = useClub();
  const { lineItems } = useCart();

  const isLandwirtschaft = club?.id === landwirtschaftShopId;

  const stickersInCart = useMemo(
    () =>
      lineItems.reduce((acc, curr) => {
        const decodedProductId = Buffer.from(curr.variant.product.id).toString('utf-8');
        if (isStickerProduct(decodedProductId)) {
          return (acc += curr.quantity);
        }
        return acc;
      }, 0),
    [lineItems]
  );

  const stickersAvailable = 41 - stickersInCart - selectedNumbers.length;
  const exclusiveGlitterStickers = club?.exclusive_glitter_stickers || false;

  const {
    addVariantToCart,
    store: { adding },
  } = useContext(StoreContext);

  const numbers = useMemo(() => {
    if (!club) {
      return [];
    }

    if (isGlitter) {
      return club.glitter_sticker_numbers.map(Number);
    }

    return Array(club.available_numbers)
      .fill()
      .map((_, number) => number + 1)
      .filter(
        number =>
          !isLandwirtschaft ||
          !club.glitter_sticker_numbers.map(Number).includes(number)
      ).filter(number => exclusiveGlitterStickers ? !club.glitter_sticker_numbers.map(Number).includes(number) : true);
  }, [club, isGlitter, isLandwirtschaft, exclusiveGlitterStickers]);

  const input = useRef(null);

  const handleAdd = num => {
    setSelectedNumbers([
      ...selectedNumbers,
      {
        id: uuidv4(),
        number: num,
      },
    ]);
    input.current.reset();
    input.current.focus();
  };

  const handleAddToCart = () => {
    addVariantToCart(variant.shopifyId, selectedNumbers.length, [
      {
        key: 'stickerNumber',
        value: selectedNumbers.map(({ number }) => number).join(', '),
      },
      {
        key: 'projectId',
        value: club.project_id.toString(),
      },
    ]);
    setSelectedNumbers([]);
  };

  const handleRemove = stickerId => {
    setSelectedNumbers(selectedNumbers.filter(({ id }) => id !== stickerId));

    if (input.current) {
      input.current.focus();
    }
  };

  const price = formatCurrency(variant.price);
  const totalPrice = formatCurrency(variant.price * selectedNumbers.length);

  return (
    <div>
      {club && club.glitter_sticker_numbers.length > 1 && (
        <div style={{ padding: '1rem', background: '#E0004D', color: 'white' }}>
          Die Nummern <b>{club.glitter_sticker_numbers.join(', ')}</b> sind{' '}
          {!isGlitter && <>{exclusiveGlitterStickers ? 'nur ' : '' }als Glitzersticker</>} verfügbar.
        </div>
      )}
      <h2>{price}</h2>
      <div className="field" data-testid="qa-selected-stickers">
        <div className="field">
          <b>{selectedNumbers.length} Sticker</b> ausgewählt:
        </div>
        {selectedNumbers.map(({ id, number: num }) => (
          <StickerNode onClick={() => handleRemove(id)} key={id} number={num} />
        ))}
      </div>
      {stickersAvailable > 0 ? (
        <>
          <div className="field">
            <label htmlFor="stickerNumber">Stickernummer eingeben</label>
            <InputSelect
              data-testid="qa-sticker-input"
              ref={input}
              type="number"
              options={numbers.map(n => [n, n.toString()])}
              onChange={({ target: { value } }) => handleAdd(value)}
            />
          </div>
          <div className="field">
            <button
              type="submit"
              data-testid="qa-add-to-cart-btn"
              disabled={selectedNumbers.length === 0 || adding}
              onClick={handleAddToCart}
            >
              Hinzufügen für {totalPrice}
            </button>
          </div>
        </>
      ) : (
        <div>Du hast schon 40 Sticker im Warenkorb!</div>
      )}
    </div>
  );
};

export default StickerForm;
